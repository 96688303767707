import EmptyState from '@components/shared/EmptyState'
import FavoriteMarketButton from '@components/shared/FavoriteMarketButton'
import RenderSwapOnlyTokens from '@components/shared/RenderSwapOnlyTokens'
import RenderTokenIcon from '@components/shared/RenderTokenIcon'
import TokenFilters from '@components/shared/TokenFilters'
import TableRowAccordian from '@components/TableRowAccordian'
import { Popover } from '@headlessui/react'
import { useCreatePoolStore } from '@store/permissionlessCreatePoolStore'
import clsx from 'clsx'
import { AprInfo } from 'components/AprInfo/AprInfo'
import SearchInput from 'components/SearchInput/SearchInput'
import { CLOUD_FRONT_URL } from 'config/constants'
import { MarketInfo } from 'domain/synthetics/markets'
import { BigNumber } from 'ethers'
import { formatTokenAmount } from 'gmx/lib/numbers'
import { PoolDataType } from 'hooks/usePerpsPoolData'
import { useViewport } from 'hooks/useViewport'
import Image from 'next/image'
import { useRouter } from 'next/router'
import ArrowDown from 'public/icons/arrow-down-grey.svg'
import React from 'react'

import { useCallback, useMemo, useState } from 'react'

export const tokenFilters: Record<string, { label: string; count: number }> = {
  All: {
    label: 'All',
    count: 0,
  },
  Crypto: {
    label: 'Crypto',
    count: 0,
  },
  Forex: {
    label: 'Forex',
    count: 0,
  },
  Commodities: {
    label: 'Commodities',
    count: 0,
  },
}

interface Props {
  selectedPool: MarketInfo
  tableData: PoolDataType[]
}

const EarnPoolSelector = ({ selectedPool, tableData }: Props) => {
  const { isMobile } = useViewport()
  const [searchQuery, setSearchQuery] = useState('')
  const [appliedFilter, setAppliedFilter] = useState('All')
  const { setMarketDetailAddress } = useCreatePoolStore()
  const router = useRouter()

  const filterDataByMarketIndex = (data: PoolDataType, marketIndex: string) =>
    data?.marketIndexName.toLowerCase().includes(marketIndex.toLowerCase())

  const filteredPoolOptions = useMemo(() => {
    let filteredData = tableData

    filteredData = filteredData?.filter(() => {
      switch (appliedFilter) {
        case 'All':
        case 'Crypto':
          return true
        default:
          return false
      }
    })

    if (searchQuery.trim() !== '') {
      filteredData = filteredData?.filter((item) =>
        filterDataByMarketIndex(item, searchQuery),
      )
    }
    return filteredData
  }, [tableData, appliedFilter, searchQuery])

  const updateTokenFiltersCount = useMemo(() => {
    const updatedFilters = { ...tokenFilters }
    Object.keys(updatedFilters).forEach((key) => {
      switch (key) {
        case 'All':
        case 'Crypto':
          updatedFilters[key].count = tableData.length || 0
          break
        case 'Forex':
        case 'Commodities':
          updatedFilters[key].count = 0
          break
        default:
          break
      }
    })
    return Object.keys(updatedFilters).map((key) => {
      return {
        label: updatedFilters[key].label,
        count: updatedFilters[key].count,
      }
    })
  }, [tableData])

  const handleTokenFilterChange = useCallback((val: string) => {
    setAppliedFilter(val)
  }, [])

  const handlePoolClick = (address: string) => {
    setMarketDetailAddress(address)
    router.push({
      pathname: '/earn',
      search: `?market=${address}`,
    })
  }
  return (
    <Popover>
      {({ open }) => {
        if (!open && searchQuery.length > 0) {
          setSearchQuery('')
        }
        return (
          <>
            <Popover.Button
              as="button"
              className="inline-flex cursor-pointer items-center gap-4 border-none bg-transparent text-th-fgd-1"
            >
              <div className="flex h-full w-max items-center gap-2">
                <div className="flex items-end justify-center gap-1">
                  <RenderTokenIcon symbol={selectedPool.indexToken.symbol} />
                  <RenderTokenIcon
                    symbol={selectedPool.shortToken.symbol}
                    size="smallest"
                  />
                </div>
                <div>
                  <p className="flex items-center space-x-2">
                    <span className="text-base font-semibold text-th-fgd-1">
                      {selectedPool.indexToken.symbol}/
                      {selectedPool.shortToken.symbol}
                    </span>
                  </p>
                  <p className="text-start text-sm font-medium text-th-fgd-3">
                    {selectedPool.name}
                  </p>
                </div>
              </div>
              <ArrowDown className={clsx(open && 'rotate-180')} />
            </Popover.Button>
            <Popover.Panel
              as="div"
              className="absolute left-0 top-full h-[506px] w-full max-w-[51rem] rounded-md"
            >
              <div
                className="absolute left-0 top-0 z-10 h-full w-full rounded-md bg-th-bkg-5 opacity-80"
                style={{
                  opacity: 0.8,
                }}
              />

              <div className="market-token-selector-glass-bg absolute left-0 top-0 z-10 h-full w-full" />

              <Image
                alt=""
                className="pointer-events-none absolute z-20 h-full w-full"
                layout="fill"
                src={CLOUD_FRONT_URL + '/images/bg-noise.png'}
              />

              <div className="relative z-10 h-full p-4">
                <SearchInput
                  className="h-12"
                  inputBoxClasses="h-12"
                  value={searchQuery}
                  setValue={(event) => setSearchQuery(event.target.value)}
                />

                <div className="mb-4 mt-3">
                  <TokenFilters
                    appliedFilter={appliedFilter}
                    changeFilter={handleTokenFilterChange}
                    options={updateTokenFiltersCount}
                    forModal
                  />
                </div>

                <div className="h-[366px]">
                  {filteredPoolOptions?.length === 0 ? (
                    <div className="flex h-full w-full items-center justify-center">
                      <EmptyState text="No Tokens Available" />
                    </div>
                  ) : (
                    <table className="h-full w-full border-collapse text-left text-sm">
                      {!isMobile && (
                        <thead className="table h-8 w-full table-fixed">
                          <tr className="text-sm text-th-fgd-2">
                            <th className="font-medium">MARKET</th>
                            <th className="font-medium">TOTAL SUPPLY</th>
                            <th className="font-medium">WALLET</th>
                            <th className="font-medium">APR</th>
                          </tr>
                        </thead>
                      )}
                      <tbody
                        className={clsx(
                          'block overflow-y-scroll',
                          isMobile ? 'h-full' : 'h-[calc(100%-32px)]',
                        )}
                      >
                        {filteredPoolOptions?.map((item) => {
                          const {
                            market,
                            indexToken,
                            apr,
                            incentiveApr,
                            totalSupply,
                            token,
                            marketIndexName,
                            marketPoolName,
                          } = item

                          return (
                            <Popover.Button
                              as="tr"
                              key={token.address}
                              className={clsx(
                                'table w-full table-fixed cursor-pointer',
                                !isMobile && 'h-12 hover:bg-th-bkg-2',
                              )}
                              onClick={() =>
                                handlePoolClick(
                                  market?.marketTokenAddress || '',
                                )
                              }
                            >
                              {isMobile ? (
                                <TableRowAccordian
                                  VisibleContent={
                                    <div className="flex items-start justify-between py-4">
                                      <div>
                                        <p className="text-[10px] font-medium text-th-fgd-3">
                                          MARKET
                                        </p>

                                        <div className="flex items-start gap-1">
                                          <div>
                                            {market?.isSpotOnly ? (
                                              <RenderSwapOnlyTokens
                                                indexTokenSymbol={
                                                  indexToken.symbol
                                                }
                                                marketPoolName={marketPoolName}
                                                size={20}
                                              />
                                            ) : (
                                              <RenderTokenIcon
                                                symbol={indexToken.symbol}
                                              />
                                            )}
                                          </div>

                                          <div className="App-card-title-info-text">
                                            <div className="App-card-info-title font-medium text-th-fgd-1">
                                              {marketIndexName}
                                            </div>
                                          </div>
                                        </div>
                                      </div>

                                      <div className="mr-2 flex items-start gap-4">
                                        <div className="flex flex-col">
                                          <span className="text-[10px] font-medium text-th-fgd-3">
                                            TOTAL SUPPLY
                                          </span>
                                          <span className="text-sm font-medium text-th-fgd-1">
                                            {formatTokenAmount(
                                              totalSupply,
                                              token.decimals,
                                              'RP',
                                              {
                                                useCommas: true,
                                                displayDecimals: 2,
                                              },
                                            )}
                                          </span>
                                        </div>
                                        <div className="flex flex-col">
                                          <span className="text-[10px] font-medium text-th-fgd-3">
                                            WALLET
                                          </span>
                                          <span className="text-sm font-medium text-th-fgd-1">
                                            {formatTokenAmount(
                                              token.balance,
                                              token.decimals,
                                              'RP',
                                              {
                                                useCommas: true,
                                                displayDecimals: 2,
                                                fallbackToZero: true,
                                              },
                                            )}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  }
                                  HiddenContent={
                                    <div className="flex flex-col pb-4">
                                      <span className="text-[10px] font-medium text-th-fgd-3">
                                        APR
                                      </span>
                                      <AprInfo
                                        apr={apr || BigNumber.from(0)}
                                        incentiveApr={incentiveApr}
                                        className="!text-base"
                                      />
                                    </div>
                                  }
                                />
                              ) : (
                                <>
                                  <td>
                                    <div className="App-card-title-info flex items-start gap-1">
                                      <div className="App-card-title-info-icon">
                                        {market?.isSpotOnly ? (
                                          <RenderSwapOnlyTokens
                                            indexTokenSymbol={indexToken.symbol}
                                            marketPoolName={marketPoolName}
                                            size={20}
                                          />
                                        ) : (
                                          <RenderTokenIcon
                                            symbol={indexToken.symbol}
                                          />
                                        )}
                                      </div>
                                      <div className="App-card-title-info-text">
                                        <div className="App-card-info-title font-medium text-th-fgd-1">
                                          {marketIndexName}
                                        </div>
                                      </div>
                                    </div>
                                  </td>
                                  <td>
                                    <p className="text-sm font-medium leading-[14px] text-th-fgd-1">
                                      {formatTokenAmount(
                                        totalSupply,
                                        token.decimals,
                                        'RP',
                                        {
                                          useCommas: true,
                                          displayDecimals: 2,
                                        },
                                      )}{' '}
                                    </p>
                                  </td>
                                  <td>
                                    <p className="text-sm font-medium leading-[14px] text-th-fgd-1">
                                      {formatTokenAmount(
                                        token.balance,
                                        token.decimals,
                                        'RP',
                                        {
                                          useCommas: true,
                                          displayDecimals: 2,
                                          fallbackToZero: true,
                                        },
                                      )}
                                    </p>
                                  </td>
                                  <td>
                                    <div className="flex items-center">
                                      <p className="text-sm font-medium leading-[14px] text-th-fgd-1">
                                        <AprInfo
                                          apr={apr || BigNumber.from(0)}
                                          incentiveApr={incentiveApr}
                                          className="!text-base"
                                        />
                                      </p>
                                      <div
                                        onClick={(e) => e.stopPropagation()}
                                        className="flex flex-1 justify-center"
                                      >
                                        <FavoriteMarketButton
                                          market={
                                            market?.marketTokenAddress || ''
                                          }
                                        />
                                      </div>
                                    </div>
                                  </td>
                                </>
                              )}
                            </Popover.Button>
                          )
                        })}
                      </tbody>
                    </table>
                  )}
                </div>
              </div>
            </Popover.Panel>
          </>
        )
      }}
    </Popover>
  )
}

export default EarnPoolSelector
