import CreatePoolModal from '@components/earn/CreateNewPool/CreatePoolModal'
import EarnHeader from '@components/earn/EarnHeader'
import PoolPage from '@components/earn/PoolPage/PoolPage'
import RenderEarnPageTable from '@components/earn/RenderEarnPageTable'
import { useCreatePoolStore } from '@store/permissionlessCreatePoolStore'
import SEO from 'components/Common/SEO'
import {
  GmSwapBox,
  Mode,
  Operation,
} from 'components/Synthetics/GmSwap/GmSwapBox/GmSwapBox'
import { MarketStats } from 'components/Synthetics/MarketStats/MarketStats'
import { getSyntheticsDepositMarketKey } from 'config/localStorage'
import { useMarketTokensData, useMarketsInfo } from 'domain/synthetics/markets'
import { useMarketTokensAPR } from 'domain/synthetics/markets/useMarketTokensAPR'
import { getTokenData } from 'domain/synthetics/tokens'
import { useChainId } from 'gmx/lib/chains'
import { getPageTitle } from 'gmx/lib/legacy'
import { useLocalStorageSerializeKey } from 'gmx/lib/localStorage'
import { formatTokenAmount } from 'gmx/lib/numbers'
import { getByKey } from 'gmx/lib/objects'
import { usePendingTxns } from 'gmx/lib/usePendingTxns'
import usePerpsPoolData, { PoolDataType } from 'hooks/usePerpsPoolData'
import { isEmpty } from 'lodash'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { cn } from 'utils/classnames'
import { useAccount } from 'wagmi'
import { initilaFilterData } from '../config/earn'
import React from 'react'
import commonStore from '@store/commonStore'

export async function getStaticProps({ locale }: { locale: string }) {
  return {
    props: {
      ...(await serverSideTranslations(locale, [
        'account',
        'close-account',
        'common',
        'notifications',
        'onboarding',
        'onboarding-tours',
        'profile',
        'settings',
        'swap',
        'trade',
        'tv-chart',
        'errors',
        'pools',
      ])),
    },
  }
}

function Index({
  shouldDisableValidation,
}: {
  shouldDisableValidation?: boolean
}) {
  const { chainId } = useChainId()
  const { address } = useAccount()
  const { collapsedState, setMarketsInfoDataStore, setCollapsedState } =
    useCreatePoolStore()
  const formattedPoolsData = usePerpsPoolData()
  const { marketsInfoData = {}, tokensData, mutate } = useMarketsInfo()
  const markets = Object.values(marketsInfoData)

  useEffect(() => {
    return () => setCollapsedState(true)
  }, [])

  const { marketTokensData: depositMarketTokensData } = useMarketTokensData({
    isDeposit: true,
  })
  const { marketTokensData: withdrawalMarketTokensData } = useMarketTokensData({
    isDeposit: false,
  })

  const { marketsTokensAPRData, marketsTokensIncentiveAprData } =
    useMarketTokensAPR()

  const [, setPendingTxns] = usePendingTxns()
  const [searchQuery, setSearchQuery] = useState<string>('')
  const { appliedFilter, setAppliedFilter } = commonStore((state) => state)

  const [createNewPoolModalOpen, setCreateNewPoolModalOpen] =
    useState<boolean>(false)
  const [operation, setOperation] = useState<Operation>(Operation.Deposit)
  let [mode, setMode] = useState<Mode>(Mode.Single)

  useEffect(() => {
    if (isEmpty(marketsInfoData)) {
      return
    }
    setMarketsInfoDataStore({ marketsInfoData, mutate })
  }, [marketsInfoData, mutate, setMarketsInfoDataStore])

  useEffect(() => {
    if (operation === Operation.Withdrawal) {
      setMode(Mode.Pair)
    }
  }, [operation])

  const [selectedMarketKey, setSelectedMarketKey] = useLocalStorageSerializeKey<
    string | undefined
  >(getSyntheticsDepositMarketKey(chainId), undefined)

  const marketInfo = getByKey(marketsInfoData, selectedMarketKey)

  const marketToken = getTokenData(
    operation === Operation.Deposit
      ? depositMarketTokensData
      : withdrawalMarketTokensData,
    selectedMarketKey,
  )

  const filterDataByMarket = useCallback(
    (data: PoolDataType, query: string) =>
      data.marketIndexName.toLowerCase().includes(query.toLowerCase()) ||
      data.token.address.toLowerCase().includes(query.toLowerCase()),
    [],
  )

  const filteredPoolsData = useMemo(() => {
    return formattedPoolsData
      .filter((data) => {
        if (appliedFilter === 'ALL') {
          return true
        }
        if (appliedFilter === 'MY POOLS') {
          return (
            formatTokenAmount(data.balance, data.token.decimals, 'RP', {
              useCommas: true,
              displayDecimals: 2,
              fallbackToZero: true,
            }) !== '0.00 RP'
          )
        }
        if (appliedFilter === 'LABS') {
          return data.market?.firstLp === address
        }
        if (appliedFilter === 'RP POOLS') {
          return data.market?.firstLp !== address
        }
        if (appliedFilter === 'L1 / L2') {
          return ['L1', 'L2'].includes(data.indexToken.tag || '')
        }
        if (appliedFilter === 'MEMES') {
          return data.indexToken.tag === 'MEME'
        }
        if (appliedFilter === 'DEFI') {
          return data.indexToken.tag === 'DEFI'
        }
        return false
      })
      .filter(
        (item) =>
          searchQuery.trim() === '' || filterDataByMarket(item, searchQuery),
      )
  }, [
    formattedPoolsData,
    appliedFilter,
    searchQuery,
    address,
    filterDataByMarket,
  ])

  const updatedFilterCount = useMemo(() => {
    return Object.entries(initilaFilterData).map(([key, filter]) => {
      let count = 0

      switch (key) {
        case 'ALL':
          count = formattedPoolsData.length
          break
        case 'MY POOLS':
          count = formattedPoolsData.filter(
            (d) =>
              formatTokenAmount(d.balance, d.token.decimals, 'RP', {
                useCommas: true,
                displayDecimals: 2,
                fallbackToZero: true,
              }) !== '0.00 RP',
          ).length
          break
        case 'LABS':
          count = formattedPoolsData.filter(
            (d) => d.market?.firstLp === address,
          ).length
          break
        case 'RP POOLS':
          count = formattedPoolsData.filter(
            (d) => d.market?.firstLp !== address,
          ).length
          break
        case 'L1L2':
          count = formattedPoolsData.filter((d) =>
            ['L1', 'L2'].includes(d.indexToken.tag || ''),
          ).length
          break
        case 'MEMES':
          count = formattedPoolsData.filter(
            (d) => d.indexToken.tag === 'MEME',
          ).length
          break
        case 'DEFI':
          count = formattedPoolsData.filter(
            (d) => d.indexToken.tag === 'DEFI',
          ).length
          break
        default:
          count = 0
      }

      return { label: filter.label, count }
    })
  }, [formattedPoolsData, address])

  const handleTokenFilterChange = useCallback((val: string) => {
    setAppliedFilter(val)
  }, [])

  const leftContent = useMemo(() => {
    if (collapsedState) {
      return (
        <div className="flex h-full w-full flex-col overflow-hidden">
          <EarnHeader
            appliedFilter={appliedFilter}
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            updatedFilterCount={updatedFilterCount}
            handleTokenFilterChange={handleTokenFilterChange}
            showCreatePoolModal={() => setCreateNewPoolModalOpen(true)}
          />

          <RenderEarnPageTable
            marketTokensData={depositMarketTokensData}
            gmList={filteredPoolsData}
          />
        </div>
      )
    }

    return (
      <div className="w-full">
        <PoolPage marketsInfoData={marketsInfoData} data={filteredPoolsData} />
      </div>
    )
  }, [
    appliedFilter,
    collapsedState,
    depositMarketTokensData,
    filteredPoolsData,
    handleTokenFilterChange,
    marketsInfoData,
    searchQuery,
    updatedFilterCount,
  ])

  return (
    <SEO title={getPageTitle('Earn')}>
      <div className="h-full w-full lg:flex lg:justify-between">
        <div className={cn('w-full shrink overflow-x-scroll')}>
          {leftContent}
        </div>
        <div
          className={cn(
            'relative flex w-full shrink-0 flex-col overflow-scroll border-l border-th-input-border-secondary max-lg:pb-20 lg:max-w-xs xl:max-w-[396px]',
          )}
        >
          {createNewPoolModalOpen ? (
            <CreatePoolModal
              isOpen={createNewPoolModalOpen}
              marketsInfoData={marketsInfoData}
              handleClose={() => setCreateNewPoolModalOpen(false)}
            />
          ) : (
            <>
              <GmSwapBox
                selectedMarketAddress={selectedMarketKey}
                markets={markets}
                shouldDisableValidation={shouldDisableValidation}
                marketsInfoData={marketsInfoData}
                tokensData={tokensData}
                onSelectMarket={setSelectedMarketKey}
                setPendingTxns={setPendingTxns}
                operation={operation}
                mode={mode}
                setMode={setMode}
                setOperation={setOperation}
              />
              <MarketStats
                marketsTokensAPRData={marketsTokensAPRData}
                marketsTokensIncentiveAprData={marketsTokensIncentiveAprData}
                marketTokensData={depositMarketTokensData}
                marketsInfoData={marketsInfoData}
                marketInfo={marketInfo}
                marketToken={marketToken}
              />
            </>
          )}
        </div>
      </div>
    </SEO>
  )
}

export default Index
