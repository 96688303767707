import Carousel from '@components/carousel'
import { useCreatePoolStore } from '@store/permissionlessCreatePoolStore'
import { MarketsInfoData } from 'domain/synthetics/markets'
import { PoolDataType } from 'hooks/usePerpsPoolData'
import { useViewport } from 'hooks/useViewport'
import {
  EarnPoolSelector,
  PoolContractsInfo,
  PoolGeneralInfo,
  PoolMarketParameters,
} from './index'

const PoolPage = ({
  marketsInfoData,
  data,
}: {
  marketsInfoData: MarketsInfoData
  data: PoolDataType[]
}) => {
  const { isMobile } = useViewport()
  const { marketDetailAddress: selectedPool } = useCreatePoolStore()
  const currentMarketInfo = Object.values(marketsInfoData).filter(
    (item) => item.marketTokenAddress === selectedPool,
  )[0]

  return (
    <div className="h-full w-full space-y-[16px] border-b border-th-input-border pb-[16px]">
      <div className="relative flex h-[88px] items-center justify-between border-b border-th-input-border px-4 sm:h-[60px] sm:px-6">
        <EarnPoolSelector selectedPool={currentMarketInfo} tableData={data} />
      </div>

      {isMobile ? (
        <Carousel>
          <PoolGeneralInfo currentMarketInfo={currentMarketInfo} />
          <PoolMarketParameters currentMarketInfo={currentMarketInfo} />
          <PoolContractsInfo currentMarketInfo={currentMarketInfo} />
        </Carousel>
      ) : (
        <div className="flex justify-between">
          <div className="basis-1/2">
            <PoolGeneralInfo currentMarketInfo={currentMarketInfo} />
          </div>

          <div className="flex basis-1/2 flex-col justify-between">
            <PoolMarketParameters currentMarketInfo={currentMarketInfo} />
            <PoolContractsInfo currentMarketInfo={currentMarketInfo} />
          </div>
        </div>
      )}
    </div>
  )
}

export default PoolPage
