import ExchangeInfoRow from 'components/Exchange/ExchangeInfoRow'
import ExchangeInfoRowValue from 'components/Exchange/ExchangeInfoRowValue'
import { MarketInfo } from 'domain/synthetics/markets'
import { formatUsd } from 'gmx/lib/numbers'
import { formatAmountHuman } from 'utils/permissionless'

const PoolMarketParameters = ({
  currentMarketInfo,
}: {
  currentMarketInfo: MarketInfo
}) => {
  function renderPoolCapCell(isLong: boolean) {
    const poolAmount = isLong
      ? currentMarketInfo.longPoolAmount
      : currentMarketInfo.shortPoolAmount

    const maxPoolUsdForDeposit = isLong
      ? currentMarketInfo.maxLongTokenPoolUsdForDeposit
      : currentMarketInfo.maxShortTokenPoolUsdForDeposit
    const token = isLong
      ? currentMarketInfo.longToken
      : currentMarketInfo.shortToken

    return `${formatAmountHuman(poolAmount, token.decimals)}${token.symbol} /
    ${formatUsd(maxPoolUsdForDeposit, {
      displayDecimals: 0,
    })}`
  }
  return (
    <div>
      <h1 className="border-b border-th-input-border p-4 text-base font-semibold uppercase leading-6 text-th-fgd-1 sm:border-b-0 sm:p-6 sm:pt-0 sm:text-xl sm:font-bold sm:leading-5 sm:text-th-fgd-2">
        Market parameters
      </h1>
      <div className="space-y-1 px-4 py-4 sm:px-6 sm:py-0">
        <ExchangeInfoRow
          label={`Long Cap Amount`}
          value={<ExchangeInfoRowValue text={renderPoolCapCell(true)} />}
        />
        <ExchangeInfoRow
          label={`Short Cap Amount`}
          value={<ExchangeInfoRowValue text={renderPoolCapCell(false)} />}
        />
        <ExchangeInfoRow
          label={`Maximum Leverage`}
          value={<ExchangeInfoRowValue text={'50'} />}
        />
        <ExchangeInfoRow
          label={`Open Interest (Available/Maximum)`}
          value={
            <ExchangeInfoRowValue
              text={`$${formatAmountHuman(
                currentMarketInfo.longInterestUsd,
                30,
              )} / $${formatAmountHuman(
                currentMarketInfo.shortInterestUsd,
                30,
              )}`}
            />
          }
        />
        <ExchangeInfoRow
          label={`Borrowing Fee`}
          value={
            <ExchangeInfoRowValue
              text={`$${formatAmountHuman(
                currentMarketInfo.totalBorrowingFees,
                30,
              )}`}
            />
          }
        />
        <ExchangeInfoRow
          label={`Funding Fee`}
          value={<ExchangeInfoRowValue text="0.0%" />}
        />
      </div>
    </div>
  )
}

export default PoolMarketParameters
