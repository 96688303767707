import { useCreatePoolStore } from '@store/permissionlessCreatePoolStore'
import ExchangeInfoRow from 'components/Exchange/ExchangeInfoRow'
import ExchangeInfoRowValue from 'components/Exchange/ExchangeInfoRowValue'
import { MarketStats } from 'components/Synthetics/MarketStats/MarketStats'
import {
  getAvailableUsdLiquidityForPosition,
  MarketInfo,
  useMarketTokensData,
} from 'domain/synthetics/markets'
import { useMarketTokensAPR } from 'domain/synthetics/markets/useMarketTokensAPR'
import { getTokenData, TokenData } from 'domain/synthetics/tokens'
import { BigNumber, ethers } from 'ethers'
import { formatUsd } from 'gmx/lib/numbers'
import groupBy from 'lodash/groupBy'
import { useMemo } from 'react'

const PoolGeneralInfo = ({
  currentMarketInfo,
}: {
  currentMarketInfo: MarketInfo
}) => {
  const { marketsInfoDataStore } = useCreatePoolStore()
  const { marketTokensData: depositMarketTokensData } = useMarketTokensData({
    isDeposit: true,
  })
  const { marketsTokensAPRData, marketsTokensIncentiveAprData } =
    useMarketTokensAPR()

  const marketToken = getTokenData(
    depositMarketTokensData,
    currentMarketInfo.marketTokenAddress,
  )

  const groupedIndexMarkets = useMemo(() => {
    const marketsWithMaxReservedUsd = Object.values(
      marketsInfoDataStore.marketsInfoData,
    ).map((marketInfo) => {
      const maxLongLiquidity = getAvailableUsdLiquidityForPosition(
        marketInfo,
        true,
      )
      const maxShortLiquidity = getAvailableUsdLiquidityForPosition(
        marketInfo,
        false,
      )

      return {
        maxLongLiquidity: maxLongLiquidity.gt(0)
          ? maxLongLiquidity
          : BigNumber.from(0),
        maxShortLiquidity: maxShortLiquidity.gt(0)
          ? maxShortLiquidity
          : BigNumber.from(0),
        marketTokenAddress: marketInfo.marketTokenAddress,
        indexTokenAddress: marketInfo.indexTokenAddress,
      }
    })
    const groupedMarketsWithIndex = groupBy(
      marketsWithMaxReservedUsd,
      (market) => market.indexTokenAddress,
    )

    return groupedMarketsWithIndex
  }, [marketsInfoDataStore?.marketsInfoData])

  function getMaxLongShortLiquidityPool(token: TokenData) {
    const indexTokenAddress = token.isNative
      ? token.wrappedAddress
      : token.address
    const currentMarkets =
      groupedIndexMarkets[
        ethers.utils.getAddress(indexTokenAddress?.toLowerCase()!)
      ]
    const maxLongLiquidityPool = currentMarkets?.reduce((prev, current) => {
      if (!prev.maxLongLiquidity || !current.maxLongLiquidity) {
        return current
      }
      return prev.maxLongLiquidity.gt(current.maxLongLiquidity) ? prev : current
    })

    const maxShortLiquidityPool = currentMarkets?.reduce((prev, current) => {
      if (!prev.maxShortLiquidity || !current.maxShortLiquidity) {
        return current
      }
      return prev.maxShortLiquidity.gt(current.maxShortLiquidity)
        ? prev
        : current
    })
    return {
      maxLongLiquidityPool,
      maxShortLiquidityPool,
    }
  }

  const { maxLongLiquidityPool, maxShortLiquidityPool } =
    getMaxLongShortLiquidityPool(currentMarketInfo.indexToken)

  return (
    <div>
      <h1 className="border-b border-th-input-border p-4 text-base font-semibold uppercase leading-6 text-th-fgd-1 sm:border-b-0 sm:p-6 sm:pt-0 sm:text-xl sm:font-bold sm:leading-5 sm:text-th-fgd-2">
        General
      </h1>
      <div className="space-y-1 px-4 py-4 sm:px-6 sm:py-0">
        <ExchangeInfoRow
          label={`Pool Name`}
          value={<ExchangeInfoRowValue text={currentMarketInfo?.name} />}
        />
        <ExchangeInfoRow
          label={`Collateral`}
          value={
            <ExchangeInfoRowValue
              text={`${currentMarketInfo?.longToken?.symbol}/${currentMarketInfo?.shortToken?.symbol}`}
            />
          }
        />
        <ExchangeInfoRow
          label={`Initial Liquidity`}
          value={
            <ExchangeInfoRowValue
              text={`${formatUsd(
                maxLongLiquidityPool?.maxLongLiquidity,
              )} / ${formatUsd(maxShortLiquidityPool?.maxShortLiquidity)}`}
            />
          }
        />
        <MarketStats
          marketsTokensAPRData={marketsTokensAPRData}
          marketsTokensIncentiveAprData={marketsTokensIncentiveAprData}
          marketTokensData={depositMarketTokensData}
          marketsInfoData={marketsInfoDataStore.marketsInfoData}
          marketInfo={currentMarketInfo}
          marketToken={marketToken}
          isPermissionLess={true}
        />
      </div>
    </div>
  )
}

export default PoolGeneralInfo
