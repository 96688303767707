import EmptyState from '@components/shared/EmptyState'
import commonStore from '@store/commonStore'
import useSettingsStore from '@store/settingsStore'
import { TokensData } from 'domain/synthetics/tokens'
import { PoolDataType } from 'hooks/usePerpsPoolData'
import dynamic from 'next/dynamic'

const GmList = dynamic(() => import('components/Synthetics/GmList/GmList'))
const ProGmList = dynamic(() => import('@pro/components/earn/ProGmList'))

interface Props {
  marketTokensData: TokensData | undefined
  gmList: PoolDataType[]
}

const RenderEarnPageTable = ({ gmList, marketTokensData }: Props) => {
  const isPro = useSettingsStore((state) => state.isPro())
  const appliedFilter = commonStore((state) => state.appliedFilter)

  if (gmList.length === 0 && appliedFilter !== 'SHARED') {
    return (
      <div className="flex h-full w-full items-center justify-center max-lg:min-h-[25vh]">
        <EmptyState text="No Pools Available" />
      </div>
    )
  }

  return (
    <div className="h-full overflow-scroll">
      {isPro ? (
        <ProGmList marketTokensData={marketTokensData} gmList={gmList} />
      ) : (
        <GmList marketTokensData={marketTokensData} gmList={gmList} />
      )}
    </div>
  )
}

export default RenderEarnPageTable
