import { IS_VERBOSE } from 'config/development'
import { DEFAULT_FAVORITE_MKTS, FAVORITE_MARKETS_KEY } from 'utils/constants'
import { create } from 'zustand'
import { persist } from 'zustand/middleware'

export type FavoritesStore = {
  symbols: string[]
  addSymbol: (symbol: string) => void
  removeSymbol: (symbol: string) => void
  reset: () => void
}

export const useFavoritesStore = create<FavoritesStore>()(
  persist(
    (set) => ({
      symbols: [],
      addSymbol: (symbol) => {
        set((state) => ({ symbols: [...state.symbols, symbol] }))
      },
      removeSymbol: (symbol) => {
        set((state) => ({ symbols: state.symbols.filter((s) => s !== symbol) }))
      },
      reset: () => {
        set({ symbols: [] })
      },
    }),
    {
      name: 'favorites-store',
      partialize: (state) => ({ symbols: state.symbols }),
      onRehydrateStorage: (state) => {
        // eslint-disable-next-line no-console
        IS_VERBOSE && console.log('[FavoritesStore] Rehydrated', state)

        return (state, error) => {
          if (error) {
            // eslint-disable-next-line no-console
            IS_VERBOSE &&
              console.error('[FavoritesStore] Error rehydrating', error)
            return { symbols: [] }
          } else {
            // eslint-disable-next-line no-console
            IS_VERBOSE &&
              console.log('[FavoritesStore] Successfully rehydrated', state)
          }

          return
        }
      },
    },
  ),
)

function migrateToNewFavoritesStore() {
  if (typeof window === 'undefined') {
    return
  }

  const hasMigrated = localStorage.getItem('migrate/favorites')

  if (hasMigrated) {
    return
  }

  const oldFavorites = localStorage.getItem(FAVORITE_MARKETS_KEY)
  if (oldFavorites) {
    const symbols = JSON.parse(oldFavorites)
    localStorage.removeItem('favorites')
    useFavoritesStore.setState({ symbols })
  } else {
    useFavoritesStore.setState({ symbols: DEFAULT_FAVORITE_MKTS })
  }

  localStorage.setItem('migrate/favorites', 'true')
}

migrateToNewFavoritesStore()
