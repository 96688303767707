import TokenFilters from '@components/shared/TokenFilters'
import useSettingsStore from '@store/settingsStore'
import SearchInput from 'components/SearchInput/SearchInput'
import useBreakpoints from 'hooks/useBreakpoints'
import React from 'react'
import { Dispatch, SetStateAction } from 'react'

interface Props {
  appliedFilter: string
  searchQuery: string
  setSearchQuery: Dispatch<SetStateAction<string>>
  updatedFilterCount: {
    label: string
    count: number
  }[]
  handleTokenFilterChange: (val: string) => void
  showCreatePoolModal: () => void
}

const EarnHeader = ({
  appliedFilter,
  handleTokenFilterChange,
  searchQuery,
  setSearchQuery,
  updatedFilterCount,
  showCreatePoolModal,
}: Props) => {
  const { below } = useBreakpoints()
  const isPro = useSettingsStore((state) => state.isPro())

  const renderSerachInput = () => (
    <SearchInput
      placeholder="Search by ETH, 0x..., DeFi, AMM or Labs"
      className="h-full w-full rounded-none border-none bg-transparent"
      inputBoxClasses="text-sm h-full w-full"
      value={searchQuery}
      setValue={(event) => setSearchQuery(event.target.value.trim())}
    />
  )

  const renderTokenFilters = () => (
    <TokenFilters
      appliedFilter={appliedFilter}
      changeFilter={handleTokenFilterChange}
      options={updatedFilterCount}
    />
  )

  if (below.lg) {
    return (
      <>
        <div className="flex h-10 w-full items-center justify-between border-b border-th-input-border pr-4">
          {renderSerachInput()}
          <button
            className="h-8 min-w-fit rounded border border-th-input-border-secondary bg-th-active px-4"
            onClick={() => showCreatePoolModal()}
          >
            <span className="text-sm font-medium text-th-fgd-1">
              CREATE NEW
            </span>
          </button>
        </div>
        <div className="flex h-12 items-center border-b border-th-input-border px-4">
          {renderTokenFilters()}
        </div>
      </>
    )
  }

  if (isPro) {
    return (
      <>
        <div className="flex h-[32px] w-full items-center justify-between border-b border-th-input-border pr-[16px]">
          {renderSerachInput()}
          <button
            className="h-[24px] min-w-fit rounded border border-th-input-border-secondary bg-th-active px-[10px]"
            onClick={() => showCreatePoolModal()}
          >
            <span className="text-[8.424px] font-semibold text-th-fgd-1">
              CREATE NEW
            </span>
          </button>
        </div>
        <div className="flex h-[40px] items-center border-b border-th-input-border px-[16px]">
          {renderTokenFilters()}
        </div>
      </>
    )
  }

  return (
    <>
      <div className="flex h-14 w-full items-center justify-between border-b border-th-input-border pl-2 pr-6">
        {renderSerachInput()}
        <button
          className="h-10 min-w-fit rounded border border-th-input-border-secondary bg-th-active px-4"
          onClick={() => showCreatePoolModal()}
        >
          <span className="text-sm font-semibold text-th-fgd-1">
            CREATE NEW
          </span>
        </button>
      </div>
      <div className="flex h-12 items-center border-b border-th-input-border px-6">
        {renderTokenFilters()}
      </div>
    </>
  )
}
export default EarnHeader
