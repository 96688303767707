import React, {
  useState,
  Children,
  ReactNode,
  MouseEvent,
  TouchEvent,
} from 'react'
import styles from './Carousel.module.css'
import Image from 'next/image'

const widthSpan = 100.1

interface Props {
  children: ReactNode[]
  infinite?: boolean
  showLeftRightArrows?: boolean
}

const Carousel = ({
  children,
  infinite = false,
  showLeftRightArrows = false,
}: Props) => {
  const [sliderPosition, setSliderPosition] = useState(0)
  const [touchStartPosition, setTouchStartPosition] = useState(0)
  const [touchEndPosition, setTouchEndPosition] = useState(0)
  const [touched, setTouched] = useState(false)
  const [swiped, setSwiped] = useState(false)
  const [mouseStartPosition, setMouseStartPosition] = useState(0)
  const [mouseEndPosition, setMouseEndPosition] = useState(0)
  const [mouseClicked, setMouseClicked] = useState(false)
  const [mouseSwiped, setMouseSwiped] = useState(false)

  const prevSlideHandler = () => {
    let newPosition = sliderPosition
    if (newPosition > 0) {
      newPosition = newPosition - 1
    } else if (infinite) {
      newPosition = children.length - 1 || 0
    }
    translateFullSlides(newPosition)
    setSliderPosition(newPosition)
  }

  const nextSlideHandler = () => {
    let newPosition = sliderPosition
    if (newPosition < children.length - 1) {
      newPosition = newPosition + 1
    } else if (infinite) {
      newPosition = 0
    }
    translateFullSlides(newPosition)
    setSliderPosition(newPosition)
  }

  const jumpToSlideHandler = (id: number) => {
    translateFullSlides(id)
    setSliderPosition(id)
  }

  const speedUpAnimation = () => {
    for (
      let i = Math.max(0, sliderPosition - 2);
      i < Math.min(children.length, sliderPosition + 3);
      i++
    ) {
      let elem = document.getElementById(`carouselitem${i}`)
      if (elem) {
        elem.classList.add(styles.FastAnimation)
      }
    }
  }

  const slowDownAnimation = () => {
    for (
      let i = Math.max(0, sliderPosition - 2);
      i < Math.min(children.length, sliderPosition + 3);
      i++
    ) {
      let elem = document.getElementById(`carouselitem${i}`)
      if (elem) {
        elem.classList.remove(styles.FastAnimation)
      }
    }
  }

  const touchStartHandler = (e: TouchEvent<HTMLDivElement>) => {
    speedUpAnimation()
    setTouchStartPosition(e.targetTouches[0].clientX)
    setTouchEndPosition(e.targetTouches[0].clientX)
    setTouched(true)
  }

  const touchMoveHandler = (e: TouchEvent<HTMLDivElement>) => {
    setTouchEndPosition(e.targetTouches[0].clientX)
    const frameWidth = document.getElementById('DisplayFrame')?.offsetWidth || 0
    const translateDist =
      ((touchEndPosition - touchStartPosition) / frameWidth) * 100
    translatePartialSlides(translateDist)
    if (touched) {
      setSwiped(true)
    }
  }

  const touchEndHandler = () => {
    if (swiped) {
      slowDownAnimation()
      if (touchStartPosition - touchEndPosition > 50) {
        nextSlideHandler()
      } else if (touchStartPosition - touchEndPosition < -50) {
        prevSlideHandler()
      } else {
        jumpToSlideHandler(sliderPosition)
      }
    }
    setTouched(false)
    setSwiped(false)
  }

  const mouseStartHandler = (
    e: MouseEvent<HTMLDivElement, globalThis.MouseEvent>,
  ) => {
    e.preventDefault()
    speedUpAnimation()
    setMouseStartPosition(e.clientX)
    setMouseEndPosition(e.clientX)
    setMouseClicked(true)
  }

  const mouseMoveHandler = (
    e: MouseEvent<HTMLDivElement, globalThis.MouseEvent>,
  ) => {
    e.preventDefault()
    const frameWidth = document.getElementById('DisplayFrame')?.offsetWidth || 0
    if (mouseClicked) {
      setMouseEndPosition(e.clientX)
      let translateDist =
        ((mouseEndPosition - mouseStartPosition) / frameWidth) * 100
      translatePartialSlides(translateDist)
      setMouseSwiped(true)
    }
  }

  const mouseEndHandler = () => {
    slowDownAnimation()
    if (mouseSwiped) {
      if (mouseStartPosition - mouseEndPosition > 100) {
        nextSlideHandler()
      } else if (mouseStartPosition - mouseEndPosition < -100) {
        prevSlideHandler()
      } else {
        jumpToSlideHandler(sliderPosition)
      }
    }
    setMouseClicked(false)
    setMouseSwiped(false)
  }

  const translatePartialSlides = (toTranslate: number) => {
    let currentTranslation = -sliderPosition * widthSpan
    let totalTranslation = currentTranslation + toTranslate
    for (var i = 0; i < children.length; i++) {
      let elem = document.getElementById(`carouselitem${i}`)
      if (elem) {
        elem.style.transform = `translateX(${totalTranslation}%)`
      }
    }
  }

  const translateFullSlides = (newPosition: number) => {
    let toTranslate = -widthSpan * newPosition
    for (var i = 0; i < children.length; i++) {
      let elem = document.getElementById(`carouselitem${i}`)
      if (elem) {
        elem.style.transform = `translateX(${toTranslate}%)`
      }
    }
  }

  const displayItems = Children.map(children, (child, index) => (
    <div
      className={styles.CarouselItem}
      id={`carouselitem${index}`}
      key={`carouselitem${index}`}
    >
      {child}
    </div>
  ))

  const positionIndicators = Children.map(children, (_, index) => (
    <div
      className={`${styles.PositionIndicator} ${
        sliderPosition === index ? styles.CurrentPosition : ''
      }`}
      onClick={() => jumpToSlideHandler(index)}
      key={`indicator${index}`}
    ></div>
  ))

  return (
    <div>
      <div className={styles.Container}>
        {showLeftRightArrows && (
          <div className={styles.LeftArrow} onClick={prevSlideHandler}>
            <Image
              src="/icons/arrow-down.svg"
              alt="arrow-left"
              className="rotate-90 opacity-50"
              width={20}
              height={20}
            />
          </div>
        )}

        <div
          className={styles.DisplayFrame}
          id="DisplayFrame"
          onTouchStart={touchStartHandler}
          onTouchMove={touchMoveHandler}
          onTouchEnd={touchEndHandler}
          onMouseDown={mouseStartHandler}
          onMouseMove={mouseMoveHandler}
          onMouseUp={mouseEndHandler}
          onMouseLeave={mouseEndHandler}
        >
          {displayItems}
        </div>
        {showLeftRightArrows && (
          <div className={styles.RightArrow} onClick={nextSlideHandler}>
            <Image
              src="/icons/arrow-down.svg"
              alt="arrow-left"
              className="-rotate-90 opacity-50"
              width={20}
              height={20}
            />
          </div>
        )}
      </div>

      <div className={styles.Navigation}>{positionIndicators}</div>
    </div>
  )
}

export default Carousel
