import CopyToClipboard from '@components/shared/CopyToClipboard'
import ExternalLink from 'components/ExternalLink/ExternalLink'
import { getExplorerUrl } from 'config/chains'
import { MarketInfo } from 'domain/synthetics/markets'
import { useChainId } from 'gmx/lib/chains'
import Image from 'next/image'
import { getShorterAddress } from 'utils/formatting'

const PoolContractsInfo = ({
  currentMarketInfo,
}: {
  currentMarketInfo: MarketInfo
}) => {
  const { chainId } = useChainId()
  const contracts: { label: string; address: string }[] = [
    {
      label: 'Pool',
      address: currentMarketInfo.marketTokenAddress,
    },
    {
      label: 'Token',
      address: currentMarketInfo.indexTokenAddress,
    },
    {
      label: 'Oracle',
      address: '0xe0792f7260851c916de30f2CD2203C13E729d29a',
    },
  ]
  return (
    <div>
      <h1 className="border-b border-th-input-border p-4 text-base font-semibold uppercase leading-6 text-th-fgd-1 sm:border-b-0 sm:p-6 sm:text-xl sm:font-bold sm:leading-5 sm:text-th-fgd-2">
        Contracts
      </h1>
      <div className="space-y-2 px-4 py-4 sm:px-6 sm:py-0">
        {contracts.map((contract, idx) => (
          <div
            key={`${contract.label}-${idx}`}
            className="flex items-center justify-between last:mb-0"
          >
            <p className="text-sm font-medium leading-[14px] text-th-fgd-3">
              {contract.label}
            </p>
            <div className="flex items-center space-x-2">
              <p className="text-sm font-medium leading-[14px] text-th-fgd-3">
                {getShorterAddress(contract.address || '', 7, 3, 4)}
              </p>
              <CopyToClipboard text={contract.address}>
                <Image
                  alt="copy to clipboard"
                  src="/icons/copy-icon.svg"
                  height={16}
                  width={16}
                />
              </CopyToClipboard>
              <ExternalLink
                href={getExplorerUrl(chainId) + 'address/' + contract.address}
                className="hover:opacity-70"
              >
                <Image
                  alt="external link"
                  src="/icons/recent-share-icon.svg"
                  height={16}
                  width={16}
                />
              </ExternalLink>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default PoolContractsInfo
