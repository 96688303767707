import { useFavoritesStore } from '@store/favoritesStore'
import StarIconFilled from 'public/icons/star-filled.svg'
import StarIconLine from 'public/icons/star-line.svg'

const FavoriteMarketButton = ({ market }: { market: string }) => {
  const favoriteMarkets = useFavoritesStore((s) => s.symbols)
  const addToFavorites = useFavoritesStore((s) => s.addSymbol)
  const removeFromFavorites = useFavoritesStore((s) => s.removeSymbol)

  return favoriteMarkets?.find((name: string) => name === market) ? (
    <button
      className="flex items-center justify-center text-th-active focus-visible:text-th-fgd-3 md:hover:text-th-fgd-3"
      onClick={() => removeFromFavorites(market)}
    >
      <StarIconFilled />
    </button>
  ) : (
    <button
      className="flex items-center justify-center text-th-fgd-3 focus-visible:text-th-active md:hover:text-th-active"
      onClick={() => addToFavorites(market)}
    >
      <StarIconLine />
    </button>
  )
}

export default FavoriteMarketButton
