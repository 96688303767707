import Tooltip from '@components/shared/Tooltip'
import clsx from 'clsx'
import { AprInfo } from 'components/AprInfo/AprInfo'
import CardRow from 'components/CardRow/CardRow'
import StatsTooltipRow from 'components/StatsTooltip/StatsTooltipRow'
import { getBridgingOptionsForToken } from 'config/bridging'
import {
  MarketInfo,
  MarketTokensAPRData,
  MarketsInfoData,
  getMarketIndexName,
  getMarketPoolName,
  getMintableMarketTokens,
  getPoolUsdWithoutPnl,
  getSellableMarketToken,
} from 'domain/synthetics/markets'
import {
  TokenData,
  TokensData,
  convertToTokenAmount,
  convertToUsd,
} from 'domain/synthetics/tokens'
import { BigNumber } from 'ethers'
import { useChainId } from 'gmx/lib/chains'
import {
  formatTokenAmount,
  formatTokenAmountWithUsd,
  formatUsd,
} from 'gmx/lib/numbers'
import { getByKey } from 'gmx/lib/objects'
import { useViewport } from 'hooks/useViewport'
import BridgingInfo from '../BridgingInfo/BridgingInfo'
import React from 'react'

type Props = {
  marketsInfoData?: MarketsInfoData
  marketTokensData?: TokensData
  marketInfo?: MarketInfo
  marketToken?: TokenData
  marketsTokensAPRData: MarketTokensAPRData | undefined
  marketsTokensIncentiveAprData: MarketTokensAPRData | undefined
  isPermissionLess?: boolean
}

export function MarketStats(p: Props) {
  const { isDesktop } = useViewport()
  const {
    marketInfo,
    marketToken,
    marketsTokensAPRData,
    marketsTokensIncentiveAprData,
    isPermissionLess,
  } = p
  const { chainId } = useChainId()

  const marketPrice = marketToken?.prices?.maxPrice
  const marketBalance = marketToken?.balance
  const marketBalanceUsd = convertToUsd(
    marketBalance,
    marketToken?.decimals,
    marketPrice,
  )

  const marketTotalSupply = marketToken?.totalSupply
  const marketTotalSupplyUsd = convertToUsd(
    marketTotalSupply,
    marketToken?.decimals,
    marketPrice,
  )

  const { longToken, shortToken, longPoolAmount, shortPoolAmount } =
    marketInfo || {}

  const mintableInfo =
    marketInfo && marketToken
      ? getMintableMarketTokens(marketInfo, marketToken)
      : undefined
  const sellableInfo =
    marketInfo && marketToken
      ? getSellableMarketToken(marketInfo, marketToken)
      : undefined

  const maxLongSellableTokenAmount = convertToTokenAmount(
    sellableInfo?.maxLongSellableUsd,
    longToken?.decimals,
    longToken?.prices.minPrice,
  )

  const maxShortSellableTokenAmount = convertToTokenAmount(
    sellableInfo?.maxShortSellableUsd,
    shortToken?.decimals,
    shortToken?.prices.minPrice,
  )

  const longPoolAmountUsd = marketInfo?.longToken
    ? getPoolUsdWithoutPnl(marketInfo, true, 'midPrice')
    : undefined
  const shortPoolAmountUsd = marketInfo?.longToken
    ? getPoolUsdWithoutPnl(marketInfo, false, 'midPrice')
    : undefined

  const apr = getByKey(marketsTokensAPRData, marketInfo?.marketTokenAddress)
  const incentiveApr = getByKey(
    marketsTokensIncentiveAprData,
    marketInfo?.marketTokenAddress,
  )
  const indexName = marketInfo && getMarketIndexName(marketInfo)
  const poolName = marketInfo && getMarketPoolName(marketInfo)

  const bridgingOprionsForToken = getBridgingOptionsForToken(longToken?.symbol)
  const shouldShowMoreInfo = Boolean(bridgingOprionsForToken)

  return (
    <div className="w-full text-sm">
      <div
        className={`flex flex-col justify-center gap-2 ${
          !isPermissionLess && 'px-4'
        } py-[10px]`}
      >
        {!isPermissionLess && (
          <>
            <CardRow
              label="Pool"
              value={indexName && poolName ? <>{indexName}</> : '...'}
              subValue={<>[{poolName}]</>}
            />

            <CardRow label={`Fees and Price Impact`} value={0} />

            <CardRow
              label={`Market`}
              value={indexName && poolName ? <>{indexName}</> : '...'}
              subValue={<>[{poolName}]</>}
            />
            <CardRow
              label={`Price`}
              value={
                <Tooltip
                  content={
                    <div>
                      <>
                        RP Token pricing includes positions' Pending PnL, Impact
                        Pool Amount and Borrow Fees.
                      </>
                    </div>
                  }
                >
                  {formatUsd(marketPrice, {
                    displayDecimals: 3,
                  }) || '...'}
                </Tooltip>
              }
            />

            <CardRow
              label={`Wallet`}
              value={
                <>
                  {
                    formatTokenAmountWithUsd(
                      marketBalance || BigNumber.from(0),
                      marketBalanceUsd || BigNumber.from(0),
                      'RP',
                      marketToken?.decimals ?? 18,
                    )?.split('(')[0]
                  }
                </>
              }
              subValue={
                <>
                  {'('}
                  {
                    formatTokenAmountWithUsd(
                      marketBalance || BigNumber.from(0),
                      marketBalanceUsd || BigNumber.from(0),
                      'RP',
                      marketToken?.decimals ?? 18,
                    )?.split('(')[1]
                  }
                </>
              }
            />

            <CardRow
              label={`APR`}
              value={<AprInfo apr={apr} incentiveApr={incentiveApr} />}
            />
          </>
        )}
        {/* ticket data segregation */}
        {isDesktop && <div className={clsx('h-4 w-full')} />}

        {isPermissionLess && (
          <>
            <CardRow
              label={`Price`}
              value={
                <Tooltip
                  content={
                    <div>
                      <>
                        RP Token pricing includes positions' Pending PnL, Impact
                        Pool Amount and Borrow Fees.
                      </>
                    </div>
                  }
                >
                  {formatUsd(marketPrice, {
                    displayDecimals: 3,
                  }) || '...'}
                </Tooltip>
              }
              className={isPermissionLess ? 'ml-2 !w-auto' : ''}
            />

            <CardRow
              label={`APR`}
              value={<AprInfo apr={apr} incentiveApr={incentiveApr} />}
              className={isPermissionLess ? 'ml-2 !w-auto' : ''}
            />
          </>
        )}
        <CardRow
          label={`Total Supply`}
          value={
            marketTotalSupply && marketTotalSupplyUsd ? (
              <>
                {
                  formatTokenAmountWithUsd(
                    marketTotalSupply,
                    marketTotalSupplyUsd,
                    'RP',
                    marketToken?.decimals,
                    {
                      displayDecimals: 0,
                    },
                  )?.split('(')[0]
                }
              </>
            ) : (
              '...'
            )
          }
          subValue={
            marketTotalSupply &&
            marketTotalSupplyUsd && (
              <>
                {'('}
                {
                  formatTokenAmountWithUsd(
                    marketTotalSupply,
                    marketTotalSupplyUsd,
                    'RP',
                    marketToken?.decimals,
                    {
                      displayDecimals: 0,
                    },
                  )?.split('(')[1]
                }
              </>
            )
          }
          className={isPermissionLess ? 'ml-2 !w-auto' : ''}
        />

        <CardRow
          label={`Buyable`}
          value={
            mintableInfo && marketTotalSupplyUsd && marketToken ? (
              <Tooltip
                content={
                  <div>
                    {marketInfo?.isSameCollaterals ? (
                      <>
                        {marketInfo?.longToken.symbol} can be used to buy RP for
                        this market up to the specified buying caps.
                      </>
                    ) : (
                      <>
                        {marketInfo?.longToken.symbol} and{' '}
                        {marketInfo?.shortToken.symbol} can be used to buy RP
                        for this market up to the specified buying caps.
                      </>
                    )}

                    <br />
                    <br />

                    <StatsTooltipRow
                      label={`Max ${marketInfo?.longToken.symbol}`}
                      value={[
                        formatTokenAmount(
                          mintableInfo?.longDepositCapacityAmount,
                          marketInfo?.longToken.decimals,
                          marketInfo?.longToken.symbol,
                        ),
                        `(${formatTokenAmount(
                          marketInfo?.longPoolAmount,
                          marketInfo?.longToken.decimals,
                          undefined,
                          {
                            displayDecimals: 0,
                          },
                        )} / ${formatTokenAmount(
                          marketInfo?.maxLongPoolAmount,
                          marketInfo?.longToken.decimals,
                          marketInfo?.longToken.symbol,
                          { displayDecimals: 0 },
                        )})`,
                      ]}
                      showDollar={false}
                    />

                    <br />

                    {!marketInfo?.isSameCollaterals && (
                      <StatsTooltipRow
                        label={`Max ${marketInfo?.shortToken.symbol}`}
                        value={[
                          formatTokenAmount(
                            mintableInfo?.shortDepositCapacityAmount,
                            marketInfo?.shortToken.decimals,
                            marketInfo?.shortToken.symbol,
                          ),
                          `(${formatTokenAmount(
                            marketInfo?.shortPoolAmount,
                            marketInfo?.shortToken.decimals,
                            undefined,
                            { displayDecimals: 0 },
                          )} / ${formatTokenAmount(
                            marketInfo?.maxShortPoolAmount,
                            marketInfo?.shortToken.decimals,
                            marketInfo?.shortToken.symbol,
                            { displayDecimals: 0 },
                          )})`,
                        ]}
                        showDollar={false}
                      />
                    )}
                  </div>
                }
              >
                {
                  formatTokenAmountWithUsd(
                    mintableInfo.mintableAmount,
                    mintableInfo.mintableUsd,
                    'RP',
                    marketToken?.decimals,
                    {
                      displayDecimals: 0,
                    },
                  )?.split('(')[0]
                }
              </Tooltip>
            ) : (
              '...'
            )
          }
          subValue={
            mintableInfo &&
            marketToken && (
              <>
                {'('}
                {
                  formatTokenAmountWithUsd(
                    mintableInfo.mintableAmount,
                    mintableInfo.mintableUsd,
                    'RP',
                    marketToken?.decimals,
                    {
                      displayDecimals: 0,
                    },
                  )?.split('(')[1]
                }
              </>
            )
          }
          className={isPermissionLess ? 'ml-2 !w-auto' : ''}
        />

        <CardRow
          label={`Sellable`}
          value={
            <Tooltip
              content={
                <div>
                  <>
                    RP can be sold for {longToken?.symbol} and{' '}
                    {shortToken?.symbol} for this market up to the specified
                    selling caps. The remaining tokens in the pool are reserved
                    for currently open Positions.
                  </>
                  <br />
                  <br />
                  <StatsTooltipRow
                    label={`Max ${marketInfo?.longToken.symbol}`}
                    value={formatTokenAmountWithUsd(
                      maxLongSellableTokenAmount,
                      sellableInfo?.maxLongSellableUsd,
                      longToken?.symbol,
                      longToken?.decimals,
                    )}
                    showDollar={false}
                  />
                  <StatsTooltipRow
                    label={`Max ${marketInfo?.shortToken.symbol}`}
                    value={formatTokenAmountWithUsd(
                      maxShortSellableTokenAmount,
                      sellableInfo?.maxShortSellableUsd,
                      shortToken?.symbol,
                      shortToken?.decimals,
                    )}
                    showDollar={false}
                  />
                </div>
              }
            >
              {
                formatTokenAmountWithUsd(
                  sellableInfo?.totalAmount,
                  sellableInfo?.totalUsd,
                  'RP',
                  marketToken?.decimals,
                  {
                    displayDecimals: 0,
                  },
                )?.split('(')[0]
              }
            </Tooltip>
          }
          subValue={
            sellableInfo &&
            marketToken && (
              <>
                {'('}
                {
                  formatTokenAmountWithUsd(
                    sellableInfo?.totalAmount,
                    sellableInfo?.totalUsd,
                    'RP',
                    marketToken?.decimals,
                    {
                      displayDecimals: 0,
                    },
                  )?.split('(')[1]
                }
              </>
            )
          }
          className={isPermissionLess ? 'ml-2 !w-auto' : ''}
        />

        <CardRow
          label={`Long Collateral`}
          value={longToken?.symbol || '...'}
          className={isPermissionLess ? 'ml-2 !w-auto' : ''}
        />
        <CardRow
          label={`Pool Amount`}
          value={
            <>
              {
                formatTokenAmountWithUsd(
                  longPoolAmount,
                  longPoolAmountUsd,
                  longToken?.symbol,
                  longToken?.decimals,
                )?.split('(')[0]
              }
            </>
          }
          subValue={
            <>
              {`(${
                formatTokenAmountWithUsd(
                  longPoolAmount,
                  longPoolAmountUsd,
                  longToken?.symbol,
                  longToken?.decimals,
                )?.split('(')[0]
              })`}
            </>
          }
          className={isPermissionLess ? 'ml-2 !w-auto' : ''}
        />
        {shouldShowMoreInfo && (
          <CardRow
            label={`More Info`}
            value={
              <BridgingInfo chainId={chainId} tokenSymbol={longToken?.symbol} />
            }
            className={isPermissionLess ? 'ml-2 !w-auto' : ''}
          />
        )}

        <CardRow
          label={`Short Collateral`}
          value={shortToken?.symbol || '...'}
          className={isPermissionLess ? 'ml-2 !w-auto' : ''}
        />
        <CardRow
          label={`Pool Amount`}
          className={isPermissionLess ? 'ml-2 !w-auto' : ''}
          value={
            <>
              {
                formatTokenAmountWithUsd(
                  shortPoolAmount,
                  shortPoolAmountUsd,
                  shortToken?.symbol,
                  shortToken?.decimals,
                )?.split('(')[0]
              }
            </>
          }
          subValue={
            <>
              {'('}
              {
                formatTokenAmountWithUsd(
                  shortPoolAmount,
                  shortPoolAmountUsd,
                  shortToken?.symbol,
                  shortToken?.decimals,
                )?.split('(')[1]
              }
            </>
          }
        />
      </div>
    </div>
  )
}
